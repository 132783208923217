import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"
import Hero from "../components/hero"
import Content from "../components/content"
import { FiMapPin, FiPhone, FiMail, FiLink2 } from "react-icons/fi"

/**
 * GraphQL Query to retrieve template data
 * The query params are part of the component pageContext,
 * this context is coming from gatsby-node.js file
 *
 * @param {String} id The consulate id
 */
export const consulateQuery = graphql`
  query consulateQuery($id: String!) {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "consulates/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    consulate: mdx(id: { eq: $id }) {
      frontmatter {
        name
        address
        phone
        email
        website
        latitude
        longitude
        keywords
      }
      excerpt
      body
    }
  }
`

const GOOGLE_API_KEY =
  process.env.GOOGLE_API_KEY || "AIzaSyAqaXyOqgPtOZEuV6aS1_oDXpgzejqPjQE"

/**
 * Consulate Template Component
 * This component is used to generate pages related to Consulate pages
 *
 * @param {Objecct} props
 */
const ConsulateTemplate = ({ data }) => {
  const { site, consulate, hero } = data
  const { frontmatter, excerpt, body } = consulate
  const { name, address, phone, email, website, latitude, longitude, keywords } = frontmatter
  // const url = `https://www.google.com/maps/embed/v1/view?key=${GOOGLE_API_KEY}&center=${latitude},${longitude}&zoom=18`;
  const url = `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${address}&zoom=16`;

  return (
    <Layout isSecondary={true}>
      <SEO title={name} description={excerpt} keywords={keywords} />

      <Hero
        type="simple"
        tagline="Consulados de Guatemala en USA"
        title={name}
        background={hero.sharp.fluid}
      />

      <section className="map-section">
        <div className="map__map">
          <iframe
            title={`Map for ${name}`}
            loading="lazy"
            frameBorder="0"
            style={{ border: false }}
            src={url}
            allowFullScreen
          />
        </div>

        <div className="map__info">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <p>Consulados de Guatemala en USA</p>
                <h2>{name}</h2>
              </div>
            </div>

            <div className="row">
              {address &&
                <div className="col-lg-12">
                  <div className="description-item">
                    <FiMapPin className="description-item__icon" />
                    <div className="description-item__content">
                      <h3>Dirección</h3>
                      <p>{address}</p>
                    </div>
                  </div>
                </div>
              }

              {phone &&
                <div className="col-lg-12">
                  <div className="description-item">
                    <FiPhone className="description-item__icon" />
                    <div className="description-item__content">
                      <h3>Número telefónico</h3>
                      <p>{phone}</p>
                    </div>
                  </div>
                </div>
              }
              {email &&
                <div className="col-lg-12">
                  <div className="description-item">
                    <FiMail className="description-item__icon" />
                    <div className="description-item__content">
                      <h3>Correo electrónico</h3>
                      <p>{email}</p>
                    </div>
                  </div>
                </div>
              }
              {website &&
                <div className="col-lg-12">
                  <div className="description-item">
                    <FiLink2 className="description-item__icon" />
                    <div className="description-item__content">
                      <h3>Página de internet</h3>
                      <p>{website}</p>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </section>

      <MDXProvider>
        <Content className="consulate_content">
          <MDXRenderer>{body}</MDXRenderer>
        </Content>
      </MDXProvider>

      <ContactForm
        email={site.siteMetadata.email}
        phone={site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default ConsulateTemplate
